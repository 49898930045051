import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

const AppNavBar = () => {
    return (
      <Box sx={{ display: 'flex', mb:2}}>
        <AppBar component="nav" position="fixed" sx={{ bgcolor: 'background.paper'}}>
          <img src="../../assets/mainLogo.png" style={{width:"200px", aspectRatio:"auto"}}/>
        </AppBar>
        <Toolbar></Toolbar>
      </Box>
    );
  }

export default AppNavBar;