import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppNavBar from './components/AppNavBar';
import Hero from './components/Hero';
import HorizontalLinearStepper from './components/HorizontalLinearStepper';

const App: React.FC = () => {

  return (
    <div>
      <CssBaseline />
      <AppNavBar></AppNavBar>
      <Hero></Hero>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "100%", maxWidth: "800px" }}>
          <HorizontalLinearStepper></HorizontalLinearStepper>
        </div>
      </div>
    </div>
  );
};


export default App;
