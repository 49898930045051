import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class DemoCarousel extends Component {
    render() {
        return (
            <div
                id="container"
                style={{
                    width: '100%',
                    maxWidth: '400px',
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 0,
                    alignItems: 'center'
                }}>

                <Carousel autoPlay={true} interval={3000} infiniteLoop={true} showStatus={false} width={"400px"}>
                    <div>
                        <img src="/assets/Example2.png" />
                    </div>
                    <div>
                        <img src="/assets/Example3.png" />
                    </div>
                </Carousel>
            </div>
        );
    }
};

export default DemoCarousel;

// Using webpack or parcel with a style loader
// import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

// Using html tag:
// <link rel="stylesheet" href="<NODE_MODULES_FOLDER>/react-responsive-carousel/lib/styles/carousel.min.css"/>