import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ImageUploader from './ImageUploader';
import ImageEditor, { ImageEditorHandles } from './ImageEditor';
import ImageDownloader from './ImageDownloader';
import { useState, useEffect } from 'react';
import DemoCarousel from './DemoCarousel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const stepArray = ['시작하기', '이미지 등록', '프로필 만들기', '이미지 저장하기']

const HorizontalLinearStepper = () => {
  // 현재 Step 에 대한 숫자 저장
  const [activeStep, setActiveStep] = React.useState(0);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);
  const [editedImageUrl, setEditedImageUrl] = useState<string | null>(null);
  const editorRef = React.useRef<ImageEditorHandles>(null);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = (stepNumber: number) => {
    if (stepNumber === 2) {
      setOpen(true);
      editorRef.current?.unSelectSticker();
      setTimeout(() => editorRef.current?.generateImageURL(), 2000);
      setTimeout(() => {
        setOpen(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }, 3000);
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleImageUpload = (file: File) => {
    setUploadedImage(file);
    let url = URL.createObjectURL(file);
    setUploadedImageUrl(url);

    return () => {
      URL.revokeObjectURL(url);
    };
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box>
            <Typography sx={{ mt: 3, mb: 3 }}>
              1. 관심이나 지지를 표시하고 싶은 뱃지를 달아<br></br>
              프로필 사진으로 사용해보세요.
            </Typography>
            <DemoCarousel></DemoCarousel>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography sx={{ mt: 3, mb: 3 }}>
              2. 프로필로 사용하실 이미지를 등록해주세요.<br></br>
              *모든 기능은 웹페이지 상에서만 동작합니다.<br></br>
              *어떠한 이미지도 서버에 저장되지 않습니다.
            </Typography>
            <ImageUploader onImageUpload={handleImageUpload} imageUrl={uploadedImageUrl} />
          </Box>
        );
      case 2:
        return (
          uploadedImage &&
          <Box>
            <Typography sx={{ mt: 3, mb: 3 }}>
              3. 관심이나 지지를 표시하고 싶은 뱃지를 선택해주세요.<br></br>
              *원하는 뱃지 이미지를 PC 에서 불러올 수도 있습니다.
            </Typography>
            <ImageEditor ref={editorRef} onEditedImage={setEditedImageUrl} imageUrl={uploadedImageUrl} />
          </Box>
        );
      case 3:
        return (
          editedImageUrl &&
          <Box>
            <Typography sx={{ mt: 3, mb: 3 }}>
              4. 이미지를 다운받으신 후 프로필 사진으로 사용해주세요.<br></br>
              *등록하고 싶은 뱃지가 있으시면, 아래 이메일로 보내주세요.<br></br>
              jenny@yourvoicematters.us
            </Typography>
            <ImageDownloader saveImageUrl={editedImageUrl} />
          </Box>
        );
      default:
        return (
          <Box>
            <Typography sx={{ mt: 2, mb: 1 }}>
              프로필로 사용하실 사진을 등록해주세요.
            </Typography>
            <DemoCarousel></DemoCarousel>
          </Box>
        );
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel={true}>
        {stepArray.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        {renderStep()}
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'center' }}>
          <Button
            variant='contained'
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            뒤로
          </Button>

          {activeStep === stepArray.length - 1 ? (
            <Button variant='contained' onClick={handleReset}>
              완료
            </Button>
          ) : (
            <Button variant='contained' onClick={() => handleNext(activeStep)}>
              다음
            </Button>
          )
          }
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      </React.Fragment>
    </Box>
  );
}

export default HorizontalLinearStepper;