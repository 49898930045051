import React from 'react';
import { Box, Button } from '@mui/material';

interface ImageDownloadProps {
  saveImageUrl: string;
}

const ImageDownloader: React.FC<ImageDownloadProps> = ({ saveImageUrl }) => {

  const downloadImage = () => {
    if (saveImageUrl) {
      const link = document.createElement('a');
      link.download = 'yourprofile.png';
      link.href = saveImageUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  // 다운로드 로직
  return (
    <div className='container-md' style={{ maxWidth: "400px" }}>
      <img src={saveImageUrl} width='100%' />
      <Button variant="contained" size="large" fullWidth={true} sx={{ mt: 1, mb: 1 }} onClick={downloadImage}>이미지 다운로드</Button >
    </div>
  )

};

export default ImageDownloader;