import React, { useImperativeHandle, useState, useEffect } from 'react';
import { Stage, Layer, Image } from 'react-konva';
import useImage from 'use-image';
import Sticker from './Sticker';
import Konva from 'konva';
import 'bootstrap/dist/css/bootstrap.min.css';  // 부트스트랩 추가
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { Autorenew } from '@mui/icons-material';


interface ImageEditorProps {
  onEditedImage: (url: string | null) => void;
  imageUrl: string | null;
}

export interface ImageProps {
  src: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

const stickerImages = [
  {
    Url: '/assets/YellowRibbon.png',
    Title: '하늘에 빛나는 별',
    Proposer: 'Sun',
    Description: '하늘에는 새로운 별이 빛나고, 우리의 마음 속에는 영원한 사랑이...',
  },
  {
    Url: '/assets/YellowRibbon2.png',
    Title: '다시 기억합니다.',
    Proposer: 'Jacky',
    Description: '지금은 우리의 삶에서 떠났지만, 우리의 기억 속에서는 영원히 함께할...',
  },
  {
    Url: '/assets/YellowRibbon3.png',
    Title: '잊지 않겠습니다.',
    Proposer: 'Sally',
    Description: '잊혀지지 않는 사랑, 영원히 기억될 삶. 우리 마음 속에 영원히...',
  },
  {
    Url: '/assets/YellowRibbon4.png',
    Title: '잊지 않을게.',
    Proposer: 'Jaden',
    Description: '우리가 볼 수 없다고 해서 끝이 아니며, 사랑은 영원히...',
  },
  {
    Url: '/assets/YellowRibbon5.png',
    Title: '기억, 책임, 약속',
    Proposer: 'Moon',
    Description: '당신의 미소는 영원히 우리 마음 속에 남아, 언제나...',
  },
];

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export interface ImageEditorHandles {
  generateImageURL: () => void;
  unSelectSticker: () => void;
}

const ImageEditor = React.forwardRef<ImageEditorHandles, ImageEditorProps>(({ onEditedImage, imageUrl }, ref) => {
  const [stageSize, setStageSize] = useState({ width: 400, height: 400 }); // 초기값 설정
  const [imageObj] = useImage(imageUrl || '');
  const [selectedSticker, setSelectedSticker] = useState<string | null>(null);
  const [images, setImages] = useState<Array<ImageProps>>([]);
  const stageRef = React.useRef<Konva.Stage>(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    unSelectSticker: () => {
      setSelectedSticker(null);
    },
    generateImageURL: () => {
      if (stageRef.current) {
        const dataURL = stageRef.current.toDataURL({ pixelRatio: 3 });
        onEditedImage(dataURL);
      }
    }
  }));

  useEffect(() => {
    const container = document.getElementById('container');
    if (imageObj && container) {
      let width = imageObj.width;
      let height = imageObj.height;

      if (container.offsetWidth < width) {
        width = container.offsetWidth;
        height = (imageObj.height * width) / imageObj.width;
      }

      setStageSize({ width, height });
    }
  }, [imageObj]);

  const addStickerToStage = (src: string) => {
    const exists = images.some(image => image.src === src);

    if (exists) {
      setShowSuccessAlert(true);
      setTimeout(() => setShowSuccessAlert(false), 1000); // 3초 후 알림 숨김
    }
    else {
      const newSticker = { src, x: (imageWidth - 50) / 2, y: (imageWidth - 50) / 2, width: 50, height: 50 };
      setImages(prevImages => [...prevImages, newSticker]);

      // 마지막으로 추가된 스티커를 선택합니다.
      setSelectedSticker(src);
    }
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const url = URL.createObjectURL(file);
      addStickerToStage(url);
    }
  };

  const imageWidth = stageSize.width;
  const imageHeight = stageSize.height;
  return (
    <div className='container-md' style={{ maxWidth: "800px", textAlign: "center" }}>
      <div className="row" style={{ padding: 0 }}>
        <div id="container" style={{ width: '50%', margin: 'auto', padding: 0 }}>
          <div style={{ width: '100%', margin: 'auto' }}>
            <Stage width={stageSize.width} height={stageSize.height} ref={stageRef}>
              <Layer>
                <Image
                  image={imageObj}
                  x={0}
                  y={0}
                  width={imageWidth}
                  height={imageHeight}
                />
                {images.map((sticker, index) => (
                  <Sticker
                    key={index}
                    src={sticker.src}
                    x={sticker.x}
                    y={sticker.y}
                    width={sticker.width}
                    height={sticker.height}
                    isSelected={sticker.src === selectedSticker}
                    onSelect={() => setSelectedSticker(sticker.src)}
                    onChange={(newAttrs) => {
                      const newImages = [...images];
                      newImages[index] = newAttrs;
                      setImages(newImages);
                    }}
                  />
                ))}
              </Layer>
            </Stage>
            <div className="d-flex gap-2" style={{ width: '100%', margin: "auto" }}>
              <Button variant="contained" onClick={() => setImages([])} size="large" fullWidth={true} sx={{ mt: 1, mb: 1 }} tabIndex={-1} startIcon={<Autorenew />}>
                다시 꾸미기
              </Button>
              <Button onClick={() => document.getElementById('stickerUpload')?.click()} variant="contained" size="large" fullWidth={true} sx={{ mt: 1, mb: 1 }} tabIndex={-1} startIcon={<CloudUploadIcon />}>
                뱃지 추가하기
                <VisuallyHiddenInput id="stickerUpload" accept=".png, .gif, .jpeg, .jpg" type="file" onChange={onFileChange}/>
              </Button>
            </div>
          </div>
        </div>
        <div style={{ width: '50%', margin: '0 auto', padding: 0 }}>
          <div style={{ width: '100%', margin: 'auto', maxHeight: imageHeight, overflowY: "scroll" }}>
            <List sx={{ width: '100%', maxWidth: '400px', bgcolor: 'background.paper' }}>
              {stickerImages.map((src, index) => (
                <Button key={index}>
                  <ListItem alignItems="flex-start" onClick={() => addStickerToStage(src.Url)}>
                    <ListItemAvatar sx={{ marginX: 1 }}>
                      <Avatar src={src.Url} variant='rounded' sx={{ width: 56, height: 56 }} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={src.Title}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="subtitle2"
                            color="text.primary"
                          >
                            by {src.Proposer} -&nbsp;
                          </Typography>
                          {src.Description}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                </Button>
              ))
              }
            </List>
          </div>
          <div style={{ height: "100%" }}>
            {showSuccessAlert && <Alert icon={<CheckIcon />} severity="info" sx={{ mt: 1, height: 46, textAlign: 'center', px: 2.5, py: 0.5 }}>이미 등록된 뱃지입니다.</Alert>}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ImageEditor;
