import React, { useCallback, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import 'bootstrap/dist/css/bootstrap.min.css';  // 부트스트랩 추가

interface ImageUploaderProps {
  onImageUpload: (file: File) => void;
  imageUrl: string | null;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onImageUpload, imageUrl }) => {
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    // 창의 넓이의 60%나 600px 중 작은 것을 사용
    const calcWidth = Math.min(window.innerWidth * 0.6, 400);
    const calcHeight = Math.min(calcWidth * (3 / 2), 300);  // 예: 2:3 비율, 최대 높이는 400px
    setContainerSize({ width: calcWidth, height: calcHeight });
  }, []);

  const onFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      onImageUpload(file);
    }
  }, [onImageUpload]);

  const onDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      const file = event.dataTransfer.items[0].getAsFile();
      if (file) {
        onImageUpload(file);
      }
      event.dataTransfer.clearData();
    }
  }, [onImageUpload]);

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  return (
    <div className='container-md' style={{maxWidth:"400px"}}>
      <div className="row row-cols-1 row-cols-lg-2">
        <div
          id="container" 
          style={{
            width: '100%', 
            margin: 'auto',
            display:'flex', 
            flexDirection:'column', 
            padding: 0,
            alignItems: 'center'}}>
          {!imageUrl ?
            <div 
              onDrop={onDrop} 
              onDragOver={onDragOver}
              onClick={() => document.getElementById('imageUpload')?.click()}
              style={{
                width: `${containerSize.width}px`,
                height: `${containerSize.height}px`,
                border: '3px dashed #ccc', 
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#333', // 텍스트 색상 변경
                cursor: 'pointer',
                backgroundColor: 'white', // 배경을 흰색으로 설정
                padding: '10px',  // 내부 패딩을 추가
                boxSizing: 'border-box'  // 패딩과 보더가 너비와 높이에 포함되도록 설정
              }}
            >
            드래그 또는 여기를 클릭하여 파일을 선택하세요.
          </div> : 
          <div>
            <img src={imageUrl} style={{width:"100%", maxWidth:"400px", aspectRatio:"auto"}}/>
          </div>
          }
          <input 
            type="file" 
            id="imageUpload" 
            accept=".png, .gif, .jpeg, .jpg" 
            onChange={onFileChange} 
            style={{ display: 'none' }} 
          />
          <Button 
            variant="contained"
            onClick={() => document.getElementById('imageUpload')?.click()}
            sx={{marginY:1}}
            size="large"
            fullWidth={true}
            startIcon={<CloudUploadIcon />}
          >
            {
                imageUrl ?
                "다시 등록하기" : "이미지 등록하기"
              }
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
