import React, { useState, useEffect } from 'react';
import { Stage, Layer, Image, Transformer } from 'react-konva';
import useImage from 'use-image';
import { ImageProps } from './ImageEditor';
import Konva from 'konva';

interface StickerProps extends ImageProps {
    isSelected: boolean;
    onSelect: () => void;
    onChange: (newAttrs: ImageProps) => void;
  }
  
const Sticker: React.FC<StickerProps> = ({ src, x, y, width, height, isSelected, onSelect, onChange }) => {
    const [stickerImage] = useImage(src);
    const shapeRef = React.useRef<Konva.Image>(null);
    const trRef = React.useRef<Konva.Transformer>(null);

    React.useEffect(() => {
        if (isSelected && trRef.current && shapeRef.current) {
        // Transformer 활성화
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer()?.batchDraw();
        }
    }, [isSelected, trRef, shapeRef]);

    return (
        <>
            <Image
                ref={shapeRef}
                image={stickerImage}
                x={x}
                y={y}
                width={width}
                height={height}
                onClick={() => {onSelect();}}
                onTap={() => {onSelect();}}
                draggable
                onDragEnd={(e) => {
                    onChange({
                    src,
                    x: e.target.x(),
                    y: e.target.y(),
                    width: e.target.width(),
                    height: e.target.height()
                    });
                }}
                onTransformEnd={() => {
                    if (shapeRef.current) {
                    const node = shapeRef.current;
                    onChange({
                        src,
                        x: node.x(),
                        y: node.y(),
                        width: node.width(),
                        height: node.height()
                    });
                    }
                }}
            />
            {isSelected && <Transformer ref={trRef} />}
        </>
    );
};

export default Sticker;