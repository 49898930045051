import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Hero = () => {
    return (
      <Box sx={{mb:6}}>
        <Typography
                variant="h4"
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, mt: 4, mb: 2}}
            >
                작은 목소리가 모여 큰 힘이 됩니다.
            </Typography>
            <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, mt: 1 }}
            >
                당신의 관심과 지지를 프로필 사진에 담아주세요.
            </Typography>
      </Box>
    );
  }

export default Hero;